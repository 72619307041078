import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled, { withTheme } from 'styled-components'
var dayjs = require('dayjs')

class Sleep extends React.Component {
  state = {
    cycleLength: '90',
    time: '',
    wakeups: [
        
    ],
    text: ''
  }

  render() {
    
    let getWakeups = () => {
        let now = dayjs().add(15, 'minute')
        let nowDisplay = dayjs().format('HH:mm')

        let wakeupArray = []

        for (let i=0; i<8; i++) {
            let wakeupTime = now.add(90, 'minute')
            now = wakeupTime
            let wakeupTimeDisplay = wakeupTime.format('HH:mm')
            wakeupArray.push(wakeupTimeDisplay)
        }

        this.setState({
            time: nowDisplay,
            wakeups: wakeupArray,
            text: `It'll take you ~15 minutes to fall asleep, so I've masterfully calculated that into the times. Love you!`
        })
    }

    const wakeups = this.state.wakeups.map((time, i) =>
        <li key={i}>{time}</li>
    );

    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const AboutWrapper = styled.div`
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      /*width: 80%;
      height: 80%;*/
    `;

    const AboutItems = styled.div`
      width: 100%;
      height: 100%;
      /*padding: 1em;*/
      margin: .5em;
      font-family: Montserrat, sans-serif;
    `;


    return (
      <Layout location={`/sleep`} title={siteTitle}>
        <SEO title="Hannah's Wakeup Calculator" />
        <AboutWrapper>
          <AboutItems>
            <button onClick={getWakeups}>Generate Wakeups (90min cycle)</button>
            <p></p>
            <p>{this.state.text}</p>
            <ul>{wakeups}</ul>
          </AboutItems>
        </AboutWrapper>
        
      </Layout>
    )
  }
}

export default Sleep

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
